import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Locker: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 59 85" fill="none">
      <path
        d="M3.84783 84.6522H55.1522C57.2772 84.6522 59 82.9294 59 80.8043V37.1957C59 35.0706 57.2772 33.3478 55.1522 33.3478H51.3043V21.8043C51.3043 9.78143 41.5229 0 29.5 0C17.4771 0 7.69565 9.78143 7.69565 21.8043V33.3478H3.84783C1.7228 33.3478 0 35.0706 0 37.1957V80.8043C0 82.9294 1.7228 84.6522 3.84783 84.6522ZM33.3478 60.279V65.413C33.3478 67.5381 31.625 69.2609 29.5 69.2609C27.375 69.2609 25.6522 67.5381 25.6522 65.413V60.279C24.0956 59.108 23.087 57.2459 23.087 55.1522C23.087 51.616 25.9638 48.7391 29.5 48.7391C33.0362 48.7391 35.913 51.616 35.913 55.1522C35.913 57.2459 34.9044 59.108 33.3478 60.279ZM15.3913 21.8043C15.3913 14.0248 21.7205 7.69565 29.5 7.69565C37.2795 7.69565 43.6087 14.0248 43.6087 21.8043V33.3478H15.3913V21.8043Z"
        fill={colors[color]}
      />
    </svg>
  );
};
