import React from 'react';
import styled, { css } from 'styled-components';

type ContentProps = {
  area: string;
  disabled: boolean;
  horizontal: boolean;
  labelTop: boolean;
};
export const Content = styled.div<ContentProps>`
  display: grid;
  grid-area: ${(props) => props.area};
  grid-template-columns: ${(props) => (props.horizontal ? '50px 1fr' : '1fr')};
  justify-content: ${(props) => (props.horizontal ? 'center' : 'left')};
  align-items: ${(props) => (props.labelTop ? 'flex-start' : 'center')};
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
  column-gap: 30px;
`;

type LabelProps = {
  bold: boolean;
  disabled: boolean;
  horizontal: boolean;
};

export const Label = styled.label<LabelProps>`
  opacity: 0.8;
  color: #212529;

  ${(props) =>
    props.horizontal &&
    css`
      display: flex;
      justify-content: end;
      align-items: center;
      white-space: nowrap;
    `}

  ${Content}:hover & {
    opacity: 1;
  }
`;

export type Props = {
  label: string;
  area?: string;
  children: React.ReactElement;
  bold?: boolean;
  disabled?: boolean;
  horizontal?: boolean;
  labelTop?: boolean;
};

export const FormField: React.FC<Props> = ({
  bold = false,
  disabled = false,
  area = 'unset',
  children,
  label,
  horizontal = false,
  labelTop = false,
}) => {
  return (
    <Content area={area} disabled={disabled} horizontal={horizontal} labelTop={labelTop}>
      <Label bold={bold} disabled={disabled} htmlFor={children.props.id} horizontal={horizontal}>
        {label}
      </Label>
      {children}
    </Content>
  );
};
