import React, { useState } from 'react';
import styled from 'styled-components';
import { ItemAlign } from '../global';
import { Icon, Names } from '../Icons/Icon';
import { Spinner } from '../Spinner';

type StyledButtonProps = {
  align: ItemAlign;
  icon: boolean;
};
const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  display: flex;

  justify-self: ${(props) => props.align};

  position: relative;
  padding: 12px 38px 12px 22px;
  font-size: 15px;

  background-color: #066be8;
  color: rgb(255, 255, 255);
  z-index: 1;
  border-radius: 25px;
  border-width: 0px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #0125c3;
  }
`;

const ArrowContainer = styled.div<{ hover: boolean }>`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  right: ${(props) => (props.hover ? '10px' : '15px')};
  transition: all 0.2s ease-in-out;
`;

type Props = {
  children?: React.ReactNode;
  disabled?: boolean;
  icon?: Names;
  align?: ItemAlign;
  loading?: boolean;
  onClick?: () => void;
};

export const Button: React.FC<Props> = ({
  icon,
  children,
  onClick,
  disabled = false,
  align = 'auto',
  loading = false,
}) => {
  const [hover, setHover] = useState(false);

  if (loading) {
    return (
      <StyledButton
        type="submit"
        onClick={() => onClick?.call(this)}
        align={align}
        disabled={disabled}
        icon={icon ? (children ? true : false) : false}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Spinner size="smaller" color="light" />
      </StyledButton>
    );
  }
  return (
    <StyledButton
      type="submit"
      onClick={() => onClick?.call(this)}
      align={align}
      disabled={disabled}
      icon={icon ? (children ? true : false) : false}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {icon && <Icon name={icon} size="arrow" />}
      <>
        <span>{children}</span>
        <ArrowContainer hover={hover}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.87988 4.12L13.7599 12L5.87988 19.88L7.99988 22L17.9999 12L7.99988 2L5.87988 4.12Z"
              fill="white"
            />
          </svg>
        </ArrowContainer>
      </>
    </StyledButton>
  );
};
