import React from 'react';
import styled from 'styled-components';

const colors = {
  primary: '#0c5df4',
  secondary: '#000',
  light: '#fff',
};
type Color = keyof typeof colors;

const sizes = {
  smaller: '12px',
  small: '20px',
  medium: '30px',
  large: '50px',
};
type Size = keyof typeof sizes;

type StyledSpinnerProps = { size: string; color: string };
export const StyledSpinner = styled.div<StyledSpinnerProps>`
  display: inline-block;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border: 3px solid transparent;
  border-radius: 50%;
  border-top-color: ${(props) => props.color};
  animation: spin 1s ease-in-out infinite;
  align-self: center;
  justify-self: center;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
`;

type Props = { size?: Size; color?: Color };
export const Spinner: React.FC<Props> = ({ size = 'small', color = 'primary' }) => {
  return (
    <Container>
      <StyledSpinner size={sizes[size]} color={colors[color]} />
    </Container>
  );
};
