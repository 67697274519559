import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const BarCode: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 100 68" fill="none">
      <path
        d="M11.111.667H0v66.666h11.111V.667zM39.057.667h-11.11v66.666h11.11V.667zM55.892.667h-11.11v66.666h11.11V.667zM100 .667H83.502v66.666H100V.667zM22.222.667h-5.387v66.666h5.387V.667zM66.667.667h-5.388v66.666h5.388V.667zM77.778.667H72.39v66.666h5.387V.667z"
        fill={colors[color]}
      />
    </svg>
  );
};
