import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Location: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 21 30" fill="none">
      <path
        d="M10.038 0C4.503 0 0 4.503 0 10.038a9.947 9.947 0 001.013 4.4l7.584 14.684a1.622 1.622 0 002.882 0l7.583-14.68a9.946 9.946 0 001.015-4.403C20.077 4.502 15.574 0 10.038 0zm0 15.293a5.26 5.26 0 01-5.254-5.255 5.26 5.26 0 015.254-5.254 5.26 5.26 0 015.255 5.254 5.26 5.26 0 01-5.255 5.255z"
        fill={colors[color]}
      />
    </svg>
  );
};
