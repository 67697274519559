import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Fast: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 155 146" fill="none">
      <g clipPath="url(#clip0)" fill={colors[color]}>
        <path d="M77.55 44.901v10.48a21.634 21.634 0 018.684 1.53 21.712 21.712 0 017.364 4.87 21.83 21.83 0 014.83 7.41 21.914 21.914 0 011.501 8.73h10.399a32.387 32.387 0 00-2.282-12.738 32.27 32.27 0 00-7.083-10.81 32.092 32.092 0 00-10.741-7.144A31.979 31.979 0 0077.549 44.9zm39.367-6.508a54.38 54.38 0 00-17.73-11.986A54.174 54.174 0 0078.23 22.21h-.659v11.22A43.377 43.377 0 0194.6 36.646a43.534 43.534 0 0114.431 9.634 43.764 43.764 0 019.55 14.524 43.934 43.934 0 013.158 17.115h11.157v-.662a54.873 54.873 0 00-4.116-21.044 54.663 54.663 0 00-11.862-17.832v.01zM0 77.898c0-20.65 8.165-40.453 22.7-55.054C37.232 8.243 56.945.04 77.5.04c20.554 0 40.267 8.203 54.801 22.804C146.835 37.445 155 57.249 155 77.898v8.142h-20.864v-8.054h10.322v-.088c-.628-17.414-7.956-33.905-20.441-46.002a66.822 66.822 0 00-46.5-18.856 66.823 66.823 0 00-46.501 18.856C18.531 43.993 11.203 60.484 10.575 77.898v.088h10.322v8.054H.033v-8.142H0z" />
        <path d="M83.128 71.146l-5.49-60.301-5.491 60.3a8.828 8.828 0 00-2.955 4.46 8.865 8.865 0 00.156 5.353 8.822 8.822 0 003.21 4.278 8.758 8.758 0 0010.16 0 8.822 8.822 0 003.208-4.278 8.865 8.865 0 00.156-5.354 8.828 8.828 0 00-2.954-4.458zM9.883 105.963v9.642h21.38v9.013H9.884v17.453H0V96.95h34.152v9.013H9.883zm57.706 36.052l-4.107-10.094H44.53l-4.107 10.105H30.363L49.58 96.641h9.148l19.217 45.396H67.622l-.033-.022zm-13.583-33.46l-5.963 14.618h11.915l-5.952-14.618zm44.54 34.122a28.472 28.472 0 01-19.042-7.215l5.82-7.016c4.063 3.31 8.28 5.516 13.397 5.516 4.063 0 6.479-1.611 6.479-4.247v-.133c0-2.515-1.537-3.795-9.026-5.736-9.038-2.317-14.869-4.821-14.869-13.768v-.133c0-8.163 6.589-13.591 15.703-13.591a25.898 25.898 0 0116.615 5.692l-5.129 7.469c-3.975-2.758-7.884-4.413-11.662-4.413-3.777 0-5.71 1.688-5.71 3.895v.132c0 2.957 1.91 3.927 9.664 5.924 9.114 2.383 14.275 5.671 14.275 13.525v.144c-.044 8.969-6.841 13.966-16.516 13.966v-.011zm42.804-36.582v35.931h-9.883v-35.92h-13.638V96.95h37.16v9.156H141.35v-.011z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H155V146H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
