import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const TV: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 31 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.663.512a1.235 1.235 0 010 1.717l-6.325 6.476a1.166 1.166 0 01-1.677 0L9.918 3.848a1.235 1.235 0 010-1.717 1.167 1.167 0 011.677 0L15.5 6.13 20.986.512a1.167 1.167 0 011.677 0z"
        fill={colors[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 9.06c-3.368 0-7.977.294-10.827.504a1.9 1.9 0 00-1.746 1.734c-.214 2.257-.472 5.48-.472 7.88 0 2.4.258 5.624.472 7.88a1.9 1.9 0 001.746 1.735c2.85.209 7.459.503 10.827.503 3.368 0 7.977-.294 10.827-.503a1.9 1.9 0 001.746-1.735c.214-2.256.472-5.48.472-7.88 0-2.4-.258-5.623-.472-7.88a1.9 1.9 0 00-1.746-1.734c-2.85-.21-7.459-.504-10.827-.504zM4.503 7.142c2.851-.21 7.535-.51 10.997-.51 3.462 0 8.146.3 10.997.51 2.07.152 3.735 1.797 3.937 3.922.214 2.262.483 5.587.483 8.114 0 2.528-.269 5.853-.483 8.115-.202 2.124-1.867 3.77-3.938 3.922-2.85.209-7.534.51-10.996.51-3.462 0-8.146-.301-10.997-.51-2.07-.152-3.735-1.798-3.937-3.922-.214-2.262-.483-5.587-.483-8.115 0-2.527.269-5.852.483-8.114.202-2.125 1.867-3.77 3.937-3.922z"
        fill={colors[color]}
      />
      <path
        d="M11.396 23.88c-.458 0-.688-.122-.688-.366V17.34h-1.82c-.23 0-.344-.191-.344-.573v-.355c0-.382.115-.572.344-.572h5.417c.229 0 .343.19.343.572v.355c0 .382-.114.573-.343.573h-1.821v6.173c0 .244-.23.366-.688.366h-.4zm6.583 0c-.168 0-.31-.015-.424-.046-.114-.038-.187-.103-.218-.194l-2.473-7.364a.41.41 0 00-.035-.103.534.534 0 01-.011-.103c0-.168.233-.252.699-.252h.4c.168 0 .313.023.436.068a.38.38 0 01.24.264l1.42 4.546c.061.183.115.374.16.573.054.198.085.355.092.47h.046c.015-.115.046-.272.091-.47.054-.199.107-.39.16-.573l1.467-4.546a.378.378 0 01.24-.252 1.08 1.08 0 01.436-.08h.332c.458 0 .687.084.687.252a.82.82 0 01-.035.206l-2.473 7.364c-.03.091-.103.156-.218.194-.114.03-.256.046-.424.046h-.595z"
        fill={colors[color]}
      />
    </svg>
  );
};
