import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Support: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 28 28" fill="none">
      <path
        d="M26.986 22.093L15.714 10.82a3.741 3.741 0 01-1.085-2.933 7.315 7.315 0 00-2.123-5.752A7.276 7.276 0 007.338 0C6.636 0 5.931.1 5.25.3l4.4 4.4c.476 2.203-2.719 5.436-4.95 4.948L.301 5.25A7.402 7.402 0 000 7.34c0 1.87.708 3.736 2.135 5.164 3.052 3.056 5.754 2.454 9.167 2.64 3.931.214 7.655 3.183 7.325 8.38l3.464 3.463A3.46 3.46 0 0024.538 28C26.636 28 28 26.288 28 24.513c0-.853-.315-1.72-1.014-2.42zM24.5 25.667a1.166 1.166 0 110-2.333 1.166 1.166 0 010 2.333zM11.083 17.5a5.252 5.252 0 00-5.25 5.25c0 2.9 2.354 5.25 5.25 5.25 2.897 0 5.25-2.35 5.25-5.25s-2.353-5.25-5.25-5.25zM14 23.333h-2.333v2.334H10.5v-2.334H8.167v-1.166H10.5v-2.334h1.167v2.334H14v1.166z"
        fill={colors[color]}
      />
    </svg>
  );
};
