import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Trash: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.09829 0.572367C8.87757 0.575818 8.66722 0.666701 8.51343 0.825071C8.35964 0.983441 8.27496 1.19636 8.27798 1.41709V1.83376H3.69465C3.58426 1.83226 3.47468 1.85271 3.37226 1.89392C3.26984 1.93512 3.17663 1.99627 3.09804 2.0738C3.01945 2.15133 2.95704 2.2437 2.91445 2.34555C2.87186 2.4474 2.84992 2.5567 2.84992 2.66709H1.61132C1.50089 2.66553 1.39125 2.68593 1.28878 2.72711C1.18631 2.76829 1.09304 2.82942 1.0144 2.90696C0.935756 2.9845 0.873309 3.07689 0.830686 3.17877C0.788063 3.28065 0.766113 3.38999 0.766113 3.50043C0.766113 3.61086 0.788063 3.7202 0.830686 3.82208C0.873309 3.92396 0.935756 4.01636 1.0144 4.09389C1.09304 4.17143 1.18631 4.23257 1.28878 4.27374C1.39125 4.31492 1.50089 4.33532 1.61132 4.33376H16.6113C16.7217 4.33532 16.8314 4.31492 16.9339 4.27374C17.0363 4.23257 17.1296 4.17143 17.2082 4.09389C17.2869 4.01636 17.3493 3.92396 17.3919 3.82208C17.4346 3.7202 17.4565 3.61086 17.4565 3.50043C17.4565 3.38999 17.4346 3.28065 17.3919 3.17877C17.3493 3.07689 17.2869 2.9845 17.2082 2.90696C17.1296 2.82942 17.0363 2.76829 16.9339 2.72711C16.8314 2.68593 16.7217 2.66553 16.6113 2.66709H15.3727C15.3727 2.5567 15.3508 2.4474 15.3082 2.34555C15.2656 2.2437 15.2032 2.15133 15.1246 2.0738C15.046 1.99627 14.9528 1.93512 14.8504 1.89392C14.748 1.85271 14.6384 1.83226 14.528 1.83376H9.94465V1.41709C9.94618 1.3056 9.92532 1.19494 9.8833 1.09166C9.84129 0.988371 9.77898 0.894568 9.70006 0.815801C9.62114 0.737033 9.52722 0.674902 9.42385 0.633088C9.32049 0.591274 9.20978 0.570626 9.09829 0.572367ZM1.61132 6.00043L3.10546 18.6957C3.20379 19.5349 3.9141 20.1671 4.7591 20.1671H13.4635C14.3085 20.1671 15.018 19.5349 15.1172 18.6957L16.6113 6.00043H1.61132Z"
        fill={colors[color]}
      />
    </svg>
  );
};
