import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const DownDetector: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 38 37" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.495.007C16.143.141 15.57.22 14.82.373A18.454 18.454 0 00.065 17.287c-.086 1.3-.087 18.907-.001 19.196.072.244.24.417.46.472.23.058 18.446.061 19.305.004 9.78-.655 17.328-8.706 17.327-18.487C37.156 8.294 29.029.117 18.803.005a30.991 30.991 0 00-1.308.002zm2.847 5.614c1.819.208 2.115.587 1.942 2.481l-.133 1.54c-.235 2.793-.369 4.357-.404 4.713-.02.211-.074.826-.117 1.366-.575 7.127-.541 6.86-.91 7.218-.375.364-1.483.51-3.082.403-1.462-.098-1.629-.286-1.74-1.965-.104-1.575-.24-3.678-.308-4.79-.041-.678-.093-1.44-.114-1.694-.02-.254-.056-.808-.078-1.23a82.67 82.67 0 00-.097-1.52c-.031-.413-.082-1.14-.113-1.617l-.14-2.097c-.164-2.466.003-2.647 2.658-2.881.38-.034 2.127.015 2.636.073zm-.481 19.814c1.44.213 1.745.81 1.682 3.289-.06 2.356-.419 2.671-3.048 2.673-2.486 0-2.807-.233-2.984-2.17-.155-1.705.098-3.097.628-3.456.553-.374 2.36-.537 3.722-.336z"
        fill={colors[color]}
      />
    </svg>
  );
};
