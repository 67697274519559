import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Network: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 100 100" fill="none">
      <path
        d="M89.09 0c-6.016 0-10.908 4.893-10.908 10.91 0 1.88.478 3.652 1.32 5.2L61.63 33.98a14.44 14.44 0 00-8.904-3.07 14.46 14.46 0 00-8.905 3.068L29.878 20.034c.64-1.081 1.031-2.325 1.031-3.67a7.28 7.28 0 00-7.273-7.273 7.28 7.28 0 00-7.272 7.273 7.28 7.28 0 007.272 7.272c1.346 0 2.59-.39 3.671-1.03l13.944 13.943a14.45 14.45 0 000 17.81L18.226 77.384a10.856 10.856 0 00-7.317-2.84C4.893 74.546 0 79.439 0 85.456c0 6.016 4.893 10.909 10.91 10.909 6.015 0 10.908-4.893 10.908-10.91 0-1.881-.478-3.652-1.32-5.2L43.824 56.93a14.46 14.46 0 007.087 2.944v18.473c-5.151.868-9.091 5.349-9.091 10.745 0 6.016 4.893 10.909 10.91 10.909 6.016 0 10.908-4.893 10.908-10.91 0-5.394-3.94-9.874-9.09-10.745V59.873a14.46 14.46 0 007.087-2.944l13.943 13.944c-.642 1.083-1.032 2.327-1.032 3.672a7.28 7.28 0 007.272 7.273 7.28 7.28 0 007.273-7.272 7.28 7.28 0 00-7.273-7.273c-1.345 0-2.589.39-3.67 1.03L64.203 54.36a14.45 14.45 0 000-17.81l17.572-17.572a10.848 10.848 0 007.315 2.84c6.016 0 10.909-4.893 10.909-10.909C100 4.893 95.107 0 89.09 0z"
        fill={colors[color]}
      />
    </svg>
  );
};
