import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Bell: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 20 22" fill="none">
      <path
        d="M16 7A6 6 0 104 7c0 7-3 9-3 9h18s-3-2-3-9zM11.73 20a1.999 1.999 0 01-3.46 0"
        stroke={colors[color]}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
