import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Dollar: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 103 103" fill="none">
      <path
        d="M51.047 0a51.048 51.048 0 10.01 102.095A51.048 51.048 0 0051.047 0zM56 74.356v5.882h-9.517v-5.861c-10.21-1.295-15.4-7.046-16.46-14.327H41.2c1.084 3.783 4.017 6.395 10.851 6.395 5.417 0 7.55-1.965 7.55-4.588 0-2.888-1.416-4.886-11.327-7.028-13.35-2.875-17.052-7.819-17.052-14.46 0-6.311 4.235-12.549 14.528-13.993v-4.967h9.512v4.946c11.193 1.431 14.53 7.883 14.932 12.538H58.833c-.72-1.87-1.958-4.739-8.972-4.739-5.512 0-7.224 2.33-7.224 4.678 0 2.609 1.91 4.134 10.122 5.9 15.726 3.375 18.785 8.448 18.785 15.379 0 6.712-4.445 12.851-15.542 14.243"
        fill={colors[color]}
      />
    </svg>
  );
};
