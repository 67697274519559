import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Play: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 29.3872C22 23.5388 27.996 19.6083 33.359 21.9412L123.058 60.96C129.203 63.6332 129.647 72.1792 123.813 75.4757L34.1143 126.155C28.7014 129.214 22 125.303 22 119.086V29.3872Z"
        fill={colors[color]}
      />
    </svg>
  );
};
