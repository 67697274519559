import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const SpeedTest: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 36 31" fill="none">
      <path
        d="M30.729 30.994A18.182 18.182 0 0036 18.157C36 8.13 27.94.003 18 .003S0 8.13 0 18.157c0 5.016 2.015 9.553 5.271 12.841l2.564-2.586a14.505 14.505 0 01-4.219-10.25c.009-8.008 6.447-14.494 14.388-14.494s14.38 6.486 14.38 14.49c0 3.999-1.61 7.622-4.21 10.246l2.555 2.59z"
        fill={colors[color]}
      />
      <path d="M17.582 21.805l-3.194-3.223 10.23-9.036 1.921 1.938-8.957 10.32z" fill={colors[color]} />
    </svg>
  );
};
