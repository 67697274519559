import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Schedule: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 22 22" fill="none">
      <path
        d="M14.7512 2C13.9109 2 13.25 2.60129 13.25 3.31862V6.91932C13.25 7.64016 13.9109 8.23794 14.7512 8.23794C15.5914 8.23794 16.2523 7.63665 16.2523 6.91932V3.31862C16.2488 2.60129 15.5914 2 14.7512 2ZM2 4.48955V5.74839H12.5012V4.48955H2ZM17.0012 4.48955V5.74839H20V4.48955H17.0012ZM7.24883 8.00234C6.40859 8.00234 5.74766 8.60363 5.74766 9.32096V12.9217C5.74766 13.6425 6.40859 14.2403 7.24883 14.2403C8.08906 14.2403 8.75 13.639 8.75 12.9217V9.32096C8.75 8.60012 8.08906 8.00234 7.24883 8.00234ZM2 10.4919V11.7507H4.99883V10.4919H2ZM9.49883 10.4919V11.7507H20V10.4919H9.49883ZM14.7512 13.7621C13.9109 13.7621 13.25 14.3634 13.25 15.0807V18.6814C13.25 19.4022 13.9109 20 14.7512 20C15.5914 20 16.2523 19.3987 16.2523 18.6814V15.0842C16.2488 14.3317 15.5914 13.7621 14.7512 13.7621ZM2 16.2516V17.5105H12.5012V16.2516H2ZM17.0012 16.2516V17.5105H20V16.2516H17.0012Z"
        fill={colors[color]}
      />
    </svg>
  );
};
