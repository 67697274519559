import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Ethernet: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 31 31" fill="none">
      <path d="M.31 15v14.7h30.38V.3H.31V15zm28.53-.029V27.91H2.1V2.033h26.74V14.97z" fill={colors[color]} />
      <path
        d="M9.8 6.885v1.502H5.98V24.56h2.268V20.4h1.91v4.16h2.268V20.4h1.911v4.16h2.268V20.4H18.514v4.16h2.268V20.4h1.91v4.16h2.268V8.386h-3.82V5.383H9.8v1.502zm9.43 1.848v1.502h3.82v8.317H7.89v-8.317h3.82V7.23h7.52v1.502z"
        fill={colors[color]}
      />
    </svg>
  );
};
