import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Speed: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 166 114" fill="none">
      <path
        d="M66.906 86.222l51.619-69.926a5.728 5.728 0 0110.191 4.696l-.254.812-31.928 80.842c-3.465 8.773-13.386 13.076-22.159 9.611a17.078 17.078 0 01-2.006-.947c-8.252-4.574-11.234-14.972-6.66-23.224l.569-.952.628-.912 51.619-69.926-51.619 69.926zm70.623-66.057a87.912 87.912 0 0116.683 17.92c4.327 6.179 8.493 13.662 10.89 19.785a6.187 6.187 0 11-11.525 4.51c-.802-2.052-1.873-4.342-3.112-6.698l-16.908 9.762a6.188 6.188 0 01-6.887-10.248l.699-.469L144.02 45.1l-1.901-2.588a75.56 75.56 0 00-8.727-9.567l3.137-7.955c.312-.792.55-1.596.716-2.405l.196-1.215.088-1.205zm-21.686-12.88a14.12 14.12 0 00-3.354 2.711l-.943 1.149-4.462 6.046a75.933 75.933 0 00-17.894-3.88l-.002 22.05a6.187 6.187 0 01-12.319.84l-.056-.84-.004-22.186c-13.172.936-26.268 5.343-37.873 13.468-6.732 4.714-12.6 10.909-17.48 18.189l17.179 9.917a6.188 6.188 0 01-5.432 11.088l-.756-.37-17.112-9.876a96.232 96.232 0 00-2.934 6.776A6.187 6.187 0 11.867 57.884C7.52 40.766 18.02 26.18 31.838 16.505c25.508-17.86 57.208-20.282 84.005-9.221z"
        fill={colors[color]}
      />
    </svg>
  );
};
