import React from 'react';
import { colors, sizes, VectorProps } from './Icon';

export const Hamburguer: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83609 1.49998L20.9609 1.49999M1.83592 9.99999H20.9609M1.83612 18.5H11.3985H20.9609"
        stroke={colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
