import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon, Names } from '../Icons/Icon';

const sizes = {
  small: '12px',
  medium: '14px',
  large: '16px',
};
type Size = keyof typeof sizes;

const width = {
  small: '100px',
  medium: '160px',
  large: '220px',
  auto: 'auto',
  full: '100%',
};
type Width = keyof typeof width;

const height = {
  small: '12px',
  medium: '15px',
  large: '18px',
  auto: 'auto',
};
type Height = keyof typeof height;

type InputProps = { size?: Size; width?: Width; height?: Height };
export const StyledInput = styled.input<InputProps>`
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0px;
  letter-spacing: 1.5px;
  border-radius: 0;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    color: #000000;
    opacity: 0.4;
  }

  &:focus {
    border-bottom: 2px solid #066be8;
  }
  /* 
  &:invalid {
    border-bottom: 5px solid red;
    border-color: red;
  } */
`;

const Container = styled.div`
  position: relative;
`;

const IconContainer = styled.div<{ focused: boolean }>`
  position: absolute;
  right: 0;
  top: 7px;
  opacity: ${(props) => (props.focused ? 1 : 0.3)};
  transition: all 0.5s ease-in-out;
`;

const handleMask = (mask: TextType, txt: string) => {
  if (mask === 'card') {
    const trimNumber = txt
      .replace(/\D/g, '')
      .replace(/^(\d{4})(\d)/g, '$1 $2')
      .replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3')
      .replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
    return trimNumber;
  } else if (mask === 'expiration') {
    const trimNumber = txt.replace(/\D/g, '').replace(/^(\d{2})(\d)/g, '$1/$2');
    return trimNumber;
  }
  return txt;
};

const types = {
  text: { type: 'text', maxLength: undefined },
  email: { type: 'email', maxLength: undefined },
  password: { type: 'password', maxLength: undefined },
  number: { type: 'number', maxLength: undefined },
  cpf: { type: 'number', maxLength: undefined },
  card: { type: 'text', maxLength: 19 },
  expiration: { type: 'text', maxLength: 5 },
  date: { type: 'date', maxLength: undefined },
  cvv: { type: 'number', maxLength: 4 },
};
export type TextType = keyof typeof types;

type Props = {
  inputRef?: React.RefObject<HTMLInputElement> | null;
  id: string;
  type: TextType;
  placeHolder?: string;
  value: string;
  icon?: Names;
  upper?: boolean;
  required?: boolean;
  onChange(value: string): void;
  onComplete?: () => void;
};
export const Input: React.FC<Props> = ({
  inputRef,
  id,
  icon,
  type,
  placeHolder,
  value,
  upper,
  required,
  onChange,
  onComplete,
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <Container>
      <StyledInput
        ref={inputRef}
        id={id}
        type={types[type].type}
        placeholder={placeHolder}
        value={value}
        maxLength={types[type].maxLength}
        onChange={(evt) => {
          onChange(handleMask(type, upper ? evt.target.value.toUpperCase() : evt.target.value));
          if (evt.target.value.length === types[type].maxLength) {
            onComplete?.call(this);
            console.log('com[');
          }
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        required={required}
      />
      {icon && (
        <IconContainer focused={focus}>
          <Icon name={icon} color={focus ? 'primary' : 'black'} size="small" />
        </IconContainer>
      )}
    </Container>
  );
};
