import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Calendar: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 23 24" fill="none">
      <path
        d="M1 7.217v15.305c0 .287.191.478.478.478h20.087c.287 0 .479-.191.479-.478V7.217"
        stroke={colors[color]}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.304 2.913H6.74M1 7.217h21.044" stroke={colors[color]} strokeMiterlimit={10} strokeLinejoin="round" />
      <path
        d="M4.826 10.087h13.391V20.13H4.826V10.087zM4.826 13.435h13.391M4.826 16.783h13.391M11.521 10.087V20.13M8.174 10.087V20.13M14.81 10.087V20.13M22.043 7.217V3.391c0-.287-.191-.478-.479-.478h-3.347"
        stroke={colors[color]}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.826 2.913H1.478c-.287 0-.478.191-.478.478v3.826M17.738 4.826h-.956c-.287 0-.478-.191-.478-.478v-2.87c0-.287.191-.478.478-.478h.956c.288 0 .479.191.479.478v2.87c0 .287-.191.478-.479.478zM6.26 4.826h-.956c-.287 0-.478-.191-.478-.478v-2.87c0-.287.191-.478.478-.478h.957c.287 0 .478.191.478.478v2.87c0 .287-.191.478-.478.478z"
        stroke={colors[color]}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
};
