import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Cash: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 34 24" fill="none">
      <path
        d="M7.525.883v.882h18.951V0H7.525v.883zM3.902 3.437v.836H30.099V2.601H3.902v.836zM.065 5.174C.028 5.202 0 9.41 0 14.51v9.271h34l-.019-9.317-.028-9.309-16.916-.027c-9.299-.01-16.944.01-16.972.046zm6.066 2.722v.827l-1.226.027-1.235.028-.028 1.273-.028 1.282H1.951V7.06h4.18v.836zm25.919 1.3v2.137H30.387l-.028-1.282-.028-1.272-1.273-.028-1.281-.028V7.06h4.273v2.137zM18.663 7.943a6.767 6.767 0 014.32 3.512c.53 1.068.706 1.811.706 3 0 1.868-.613 3.345-1.95 4.692-.874.892-1.636 1.356-2.77 1.737-.612.204-.891.232-1.922.241-1.375.01-2.081-.157-3.252-.761-.957-.502-2.21-1.775-2.73-2.778-.753-1.486-.967-3.252-.567-4.803.64-2.527 2.88-4.57 5.471-4.979.679-.111 2.016-.037 2.694.14zM3.642 18.83l.028 1.282 1.235.028 1.226.027V21.83h-4.18v-4.272H3.614l.028 1.272zm28.408.864v2.136h-4.274v-1.662l1.282-.028 1.273-.028.027-1.282.028-1.272H32.05v2.136z"
        fill={colors[color]}
      />
      <path
        d="M16.61 9.726l-.028.53-.53.111c-1.346.306-2.052 1.068-2.043 2.211 0 1.282.613 1.895 2.527 2.517 1.338.437 1.672.67 1.672 1.152 0 .994-1.477 1.263-2.935.52-.381-.195-.706-.334-.725-.316-.102.112-.613 1.208-.613 1.32 0 .269 1.208.752 2.137.863l.548.056.027.53.028.52h.743l.028-.52.028-.512.697-.204c.603-.177.78-.279 1.189-.697.53-.538.706-.956.706-1.728 0-1.198-.734-1.932-2.415-2.424-1.125-.325-1.487-.483-1.673-.724-.26-.316-.232-.549.093-.874.288-.278.298-.278 1.097-.241.613.037.938.102 1.356.288.297.14.576.25.622.25.093 0 .641-1.16.641-1.347 0-.157-.762-.473-1.486-.603-.334-.066-.66-.13-.715-.149-.084-.019-.121-.214-.121-.548v-.511h-.827l-.028.53z"
        fill={colors[color]}
      />
    </svg>
  );
};
