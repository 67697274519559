import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const ArrowDown: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 110 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="9.15079"
        y1="7"
        x2="55"
        y2="52.8492"
        stroke={colors[color]}
        strokeWidth="14.9412"
        strokeLinecap="round"
      />
      <line
        x1="6.47059"
        y1="-6.47059"
        x2="71.3112"
        y2="-6.47059"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 110 7)"
        stroke={colors[color]}
        strokeWidth="14.9412"
        strokeLinecap="round"
      />
    </svg>
  );
};
