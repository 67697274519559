import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Phone: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 163 182" fill="none">
      <path
        d="M159.931 167.37l-14.919 9.848c-9.604 6.614-50.53 14.049-106.21-53.404C-14.878 58.873.471 22.86 7.671 14.554l12.39-12.876c2.92-2.491 7.327-2.198 9.838.81l32.583 38.544.02.02c2.384 2.824 2.306 7.22-.83 9.868v.029l-14.44 10.415c-5.716 5.667-.733 14.587 5.11 23.204l27.786 32.808c12.926 12.477 20.009 18.241 26.194 14.118l12.008-13.395c2.941-2.472 7.328-2.169 9.839.821l32.603 38.543v.02c2.372 2.833 2.284 7.22-.842 9.887z"
        fill={colors[color]}
      />
    </svg>
  );
};
