import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Settings: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 17 17" fill="none">
      <path
        d="M17 9.645V7.355C15.83 6.938 15.093 6.822 14.72 5.924C14.347 5.024 14.79 4.412 15.32 3.299L13.702 1.68C12.597 2.205 11.979 2.654 11.076 2.28C10.176 1.907 10.059 1.164 9.645 0H7.355C6.943 1.158 6.825 1.907 5.924 2.28C5.024 2.654 4.413 2.21 3.298 1.68L1.681 3.299C2.208 4.409 2.654 5.023 2.281 5.924C1.906 6.825 1.158 6.943 0 7.355V9.644C1.155 10.056 1.907 10.175 2.28 11.075C2.656 11.983 2.199 12.609 1.68 13.7L3.298 15.319C4.405 14.794 5.022 14.345 5.923 14.719C6.823 15.092 6.942 15.837 7.355 16.999H9.644C10.056 15.841 10.174 15.094 11.081 14.717C11.975 14.345 12.583 14.788 13.701 15.319L15.319 13.7C14.794 12.593 14.345 11.977 14.718 11.075C15.092 10.176 15.844 10.056 17 9.645ZM8.5 11.334C6.936 11.334 5.667 10.065 5.667 8.5C5.667 6.935 6.936 5.666 8.5 5.666C10.064 5.666 11.333 6.935 11.333 8.5C11.333 10.065 10.064 11.334 8.5 11.334Z"
        fill={colors[color]}
      />
    </svg>
  );
};
