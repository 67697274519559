import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const CashHand: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 30 30" fill="none">
      <path
        d="M18.664.36c-3.738.553-6.48 2.806-7.676 6.341-.188.58-.29 1.259-.34 2.278-.088 1.85.139 2.957.932 4.555.465.93.792 1.359 1.66 2.24 1.209 1.207 2.228 1.849 3.763 2.352.83.265 1.208.315 2.604.328 1.8.012 2.794-.202 4.215-.932 2.039-1.044 3.725-3.007 4.48-5.221.264-.793.352-1.309.402-2.479.076-1.85-.163-2.97-.956-4.542-.465-.944-.768-1.36-1.648-2.228-.604-.59-1.41-1.258-1.8-1.484C22.678.612 20.363.12 18.665.36zm2.705 2.504c4.127 1.082 6.266 5.725 4.366 9.537-.591 1.208-1.925 2.542-3.045 3.083-1.082.503-1.724.642-3.02.654-1.95 0-3.334-.566-4.73-1.95-1.02-1.007-1.586-2.039-1.876-3.397-.226-1.108-.226-1.661 0-2.743a6.858 6.858 0 014.782-5.134c.956-.277 2.554-.302 3.523-.05z"
        fill={colors[color]}
      />
      <path
        d="M19.242 3.632c-.038.113-.075.39-.075.616 0 .44-.038.466-.83.63-1.158.226-2.064 1.32-2.064 2.465.012 1.347.793 2.114 2.856 2.781 1.233.39 1.925.856 1.925 1.284 0 .377-.29.817-.654.969-.591.239-1.812.075-2.718-.378l-.793-.402-.364.792c-.24.554-.315.843-.227.944.176.226 1.41.654 2.152.767.578.076.654.126.692.403.088.805.138.88.591.88.415 0 .428-.012.466-.578l.037-.579.793-.251c2.164-.655 2.856-2.995 1.334-4.467-.466-.453-.768-.604-2.026-.994-1.975-.629-2.428-1.07-1.736-1.749.54-.553 1.648-.478 3.359.252.176.075.277-.05.54-.642.177-.403.303-.793.265-.88-.075-.202-1.245-.63-2-.743l-.591-.088V3.43h-.428c-.277-.001-.453.075-.504.2zM7.277 17.585c-3.083.591-3.7.692-4.24.692-.68 0-1.284.252-1.485.617-.214.415-.139.981.176 1.359.302.34.352.352 1.422.327.667-.013 1.9-.176 3.12-.415 2.718-.554 3.221-.541 4.718.063 2.328.93 4.492 2.025 4.593 2.315.126.402-.038.755-.39.843-.302.075-1.812-.252-4.177-.906-1.321-.378-1.774-.303-2.101.34-.24.44-.24.666 0 1.107.226.453.629.616 3.259 1.27 1.749.44 2.29.516 3.032.479 1.082-.05 1.774-.44 2.302-1.284l.34-.541 3.234-.78c2.58-.63 3.284-.755 3.498-.642.34.176.327.705-.025 1.02-.327.29-4.731 2.403-6.367 3.057-2.706 1.082-3.939 1.082-7.764.012-3.762-1.044-4.744-1.233-6.757-1.245-1.648-.013-1.723 0-2.038.29-.453.427-.466 1.232 0 1.66.29.277.453.302 2.101.378 2.039.088 2.693.213 6.165 1.182 3.284.894 4.14 1.032 5.663.843 1.95-.239 3.246-.73 8.015-3.02 2.97-1.409 3.56-2.025 3.573-3.674.013-1.71-1.208-2.906-2.957-2.893-.302 0-1.9.34-3.536.742l-2.982.73-.377-.554c-.34-.478-.642-.667-2.554-1.61-2.806-1.372-4.543-1.95-5.712-1.938a13.99 13.99 0 00-1.75.176z"
        fill={colors[color]}
      />
    </svg>
  );
};
