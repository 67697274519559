import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Pix: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 20 20" fill="none">
      <path
        d="M15.598 15.296a2.913 2.913 0 01-2.078-.858l-2.995-2.995a.577.577 0 00-.785 0l-3.007 3.008a2.914 2.914 0 01-2.077.857h-.592l3.804 3.804a3.047 3.047 0 004.3 0l3.816-3.816h-.386zM4.644 4.692c.785 0 1.521.302 2.077.858l3.007 3.007a.554.554 0 00.785 0l3.008-2.995a2.914 2.914 0 012.077-.858h.362L12.144.888a3.047 3.047 0 00-4.3 0L4.04 4.692h.604z"
        fill={colors[color]}
      />
      <path
        d="M19.112 7.856L16.806 5.55a.386.386 0 01-.17.037h-1.05c-.544 0-1.075.217-1.45.604l-2.995 2.995a1.426 1.426 0 01-2.029 0L6.105 6.178a2.07 2.07 0 00-1.45-.604H3.365a.334.334 0 01-.157-.037l-2.32 2.32a3.047 3.047 0 000 4.299l2.307 2.306a.335.335 0 01.157-.036h1.293c.543 0 1.075-.217 1.449-.604L9.1 10.815c.544-.543 1.498-.543 2.041 0l2.995 2.995a2.07 2.07 0 001.45.604h1.05c.06 0 .11.012.17.037l2.306-2.307a3.032 3.032 0 000-4.288z"
        fill={colors[color]}
      />
    </svg>
  );
  svg;
};
