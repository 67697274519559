import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Telegram: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 178 152" fill="none">
      <path
        d="M161.728 1.84L4.392 64.32c-5.112 1.704-4.544 9.088.568 10.792l39.76 12.496 15.904 49.984c1.136 3.976 6.248 5.112 9.088 2.272L93 118.28l44.304 32.376c3.408 2.272 7.952.568 9.088-3.408l30.672-131.776c1.704-9.656-6.816-17.04-15.336-13.632zM69.712 97.264L64.6 126.8 53.24 85.904 164 13.2 69.712 97.264z"
        fill={colors[color]}
      />
    </svg>
  );
};
