import React from 'react';
import styled from 'styled-components';

const colors = {
  sucess: { color: '#ffffff', background: '#3E87D3' },
  error: { color: '#ffffff', background: 'rgb(255, 0, 0)' },
};
type Color = keyof typeof colors;

type StyledAlertProps = { schema: { color: string; background: string; border: string } };
const StyledAlert = styled.div<StyledAlertProps>`
  width: 240px;
  grid-area: Feedback;
  text-align: center;
  justify-self: center;
  border-radius: 3px;
  font-size: 12px;
  padding: 10px 10px;
  background-color: ${(props) => props.schema.background};
  color: ${(props) => props.schema.color};
`;

type Props = {
  text: string;
  color: Color;
  onClick?: () => void;
};

export const Alert: React.FC<Props> = ({ text, color, onClick }) => {
  return (
    <StyledAlert schema={colors[color]} onClick={() => onClick?.call(this)}>
      {text}
    </StyledAlert>
  );
};
