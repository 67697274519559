import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const HelpDesk: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 108 108" fill="none">
      <path
        d="M49.7.134C37.443 1.503 26.24 7.862 19.038 17.486c-3.074 4.127-4.99 7.728-6.528 12.403-.864 2.57-1.79 6.465-1.79 7.476 0 .316-.21.484-.884.674-1.285.337-3.243 1.369-4.613 2.4-2.063 1.601-3.58 3.77-4.548 6.529l-.59 1.685-.064 8.213c-.084 9.392.021 10.213 1.454 13.12 1.536 3.116 4.295 5.58 7.77 6.906 1.474.548 1.79.59 4.633.59 2.653.02 3.243-.042 4.422-.463 4.148-1.474 7.392-4.717 8.887-8.887.463-1.327.484-1.6.548-9.898.063-9.645 0-10.36-1.432-13.225-.97-1.937-2.506-3.706-4.318-4.99l-1.306-.948.295-1.727c.4-2.232 1.706-6.17 2.8-8.466 2.444-5.117 7.013-10.298 11.9-13.435 5.327-3.454 10.97-5.18 17.415-5.391 5.58-.147 10.024.631 14.699 2.59 4.148 1.77 7.687 4.17 10.95 7.476 4.507 4.549 7.644 10.424 8.93 16.721.188.906.315 1.685.294 1.706-.042.042-.8.548-1.685 1.138-2.991 2-5.012 4.801-5.854 8.15-.38 1.474-.422 2.358-.422 9.77 0 9.056.127 10.067 1.474 12.594 1.2 2.254 3.39 4.57 5.412 5.706.38.19-.105 1.264-1.958 4.296-5.727 9.392-14.51 15.436-24.913 17.12-1.81.295-6.654.4-7.265.169-.19-.064-.652-.569-1.032-1.117-.4-.548-1.179-1.305-1.727-1.663-.969-.653-4.548-1.916-7.475-2.674-3.75-.97-7.098.421-8.634 3.558-1.2 2.444-1.032 4.949.463 7.202 1.01 1.474 2.443 2.379 5.454 3.411 6.254 2.106 8.613 1.958 11.245-.674l1.053-1.074 2.526.063c9.96.274 19.543-3.074 27.187-9.497 4.633-3.897 8.845-9.435 11.35-14.995.464-1.052.927-2 .99-2.126.084-.126.759-.295 1.475-.357 4.295-.4 8.697-3.517 10.676-7.56 1.39-2.801 1.495-3.623 1.495-12.446 0-8.802-.105-9.624-1.495-12.488-1.39-2.844-4.001-5.286-7.033-6.55l-1.032-.442-.569-2.821C94.11 17.212 79.768 3.524 61.258.427 58.837.029 52.163-.139 49.7.134z"
        fill={colors[color]}
      />
    </svg>
  );
};
