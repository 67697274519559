import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Alert } from '../components/Alert';
import { Button } from '../components/Button';
import { CreditCard } from '../components/CreditCard';
import { getCardFlag } from '../components/CreditCard/CreditCard';
import { Flag } from '../components/CreditCard/Flag';
import { FormField } from '../components/FormField';
import { Icon } from '../components/Icons';
import { Input } from '../components/Input';
import { Spinner } from '../components/Spinner';
import { useMedia } from '../hooks/mediaQuery';
import { serviceInfo, ServiceInfoResponse, signService, SignServiceInput } from '../scripts/api';
import { formatCash } from '../scripts/global';

const Container = styled.div`
  display: flex;
  overflow: auto;
  scroll-behavior: smooth;
  flex-direction: column;
  padding: 30px;

  justify-content: center;
  align-items: center;

  @media (max-width: 440px) {
    padding: 0;
  }

  @media (min-height: 770px) {
    height: 94vh;
  }
`;

const PageContent = styled.div<{ active: boolean }>`
  display: grid;
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.active ? '1' : '0')};
  width: ${(props) => (props.active ? 'auto' : '0')};
  height: ${(props) => (props.active ? 'auto' : '0')};
  border-radius: 15px;
  grid-template-columns: max-content max-content;
  overflow: hidden;

  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 10%);
  box-sizing: border-box;

  transition: all 0.8s ease-in-out;

  @media (max-width: 850px) {
    grid-template-columns: max-content;
  }
`;

const LeftContent = styled.div`
  display: grid;
  padding: 40px;
  height: 89%;

  justify-content: center;
  align-items: flex-start;
  background-color: #1010af;

  row-gap: 20px;
`;

const RightContent = styled.div`
  display: grid;
  justify-content: center;
  padding: 40px;
  row-gap: 50px;
`;

const ServicesContainer = styled.div`
  display: grid;
  row-gap: 4px;
  border-bottom: solid 1px #fff;
  padding-bottom: 20px;
`;

const Item = styled.div`
  display: grid;
  justify-content: space-between;
  width: 300px;
  grid-template-columns: max-content max-content;
  column-gap: 20px;

  div {
    font-weight: 700;
  }
`;

const Option = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: max-content max-content;
  width: 200px;
  column-gap: 10px;
  align-items: center;
`;

const OptionTitle = styled.span`
  font-size: 13px;
  grid-area: 1 / 2;
  font-weight: 100;
`;

const OptionValue = styled.span`
  grid-area: 2 / 2;
  font-weight: 500;
`;

const FormContainer = styled.form`
  display: grid;
  gap: 20px;

  grid-template:
    'Card Card' max-content
    'Expiration CVV' max-content
    'Name Name' max-content
    'Feedback Feedback' max-content
    'Button Button' max-content /
    max-content max-content;

  @media (max-width: 440px) {
    grid-template:
      'Card' max-content
      'Expiration' max-content
      'CVV' max-content
      'Name' max-content
      'Feedback' max-content
      'Button' max-content /
      260px;
  }
`;

const ButtonContainer = styled.div`
  grid-area: Button;
  display: grid;
  color: #ffffff;
  font-size: 12px;
  gap: 12px;
  margin-top: 15px;
`;

const FullContainer = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  color: #000000;
  justify-content: center;
  align-items: center;

  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.active ? '1' : '0')};
  width: ${(props) => (props.active ? 'auto' : '0')};
  height: ${(props) => (props.active ? '85vh' : '0')};
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #000000;
  justify-content: center;
  align-items: center;
  padding: 40px;

  span {
    color: #373737;
    width: 350px;
    text-align: center;
  }
`;

const Copyright = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  justify-self: center;
  padding: 40px;
`;

export const Checkout: React.FC = () => {
  const [activePage, setActivePage] = useState(0);
  const [searchParams] = useSearchParams();
  const [info, setInfo] = useState<ServiceInfoResponse>();

  const [data, setData] = useState<SignServiceInput>({
    card: '',
    cvv: '',
    expireAt: '',
    name: '',
  });
  const [pedding, setPedding] = useState(false);
  const [error, setError] = useState<string>();

  const providerId = searchParams.get('provider');
  const isMobile = useMedia('(max-width: 440px)');

  const inputRef = {
    card: useRef<HTMLInputElement>(null),
    expiration: useRef<HTMLInputElement>(null),
    cvv: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
  };

  const getServiceInfo = useCallback(async () => {
    if (providerId) {
      await serviceInfo(providerId)
        .then((res) => {
          setInfo(res);
        })
        .catch(() => setError('Promoção inválida ou já utilizada.'));
    }
  }, [providerId]);

  useEffect(() => {
    getServiceInfo();
  }, []);

  useEffect(() => {
    inputRef.card.current?.focus();
  }, [info]);

  const handleSumit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setPedding(true);
    console.log(data);

    await signService(providerId ?? '', data)
      .then(() => {
        setActivePage(1);
      })
      .catch(() => {
        setPedding(false);
        setError('Não possivel concluir está operação, verifique os dados do seu cartão de crédito.');
      });
  };

  if (providerId && info) {
    return (
      <Container>
        <PageContent active={activePage === 0}>
          <LeftContent>
            <Icon name="Cash" />
            <h2>{formatCash(info.service.Plan.value)} / Mês</h2>
            <ServicesContainer>
              {info.service.includes.map((include) => {
                const style: React.CSSProperties | undefined =
                  include.operation === 'SUB' ? { opacity: 0.7, textAlign: 'end' } : undefined;

                return (
                  <Item>
                    <span style={style}>{include.title}</span>
                    <div style={style}>
                      {include.operation === 'SUB' && '- '}
                      {formatCash(include.value)}
                    </div>
                  </Item>
                );
              })}
              <br />
              <Item>
                <span>Valor total</span>
                <div>{formatCash(info.service.Plan.value)}</div>
              </Item>
            </ServicesContainer>
            <Option>
              <Icon name="Schedule" size="small" />
              <OptionTitle>CNPJ</OptionTitle>
              <OptionValue>{info.cnpj}</OptionValue>
            </Option>
            <Option>
              <Icon name="Ethernet" size="small" />
              <OptionTitle>Empresa</OptionTitle>
              <OptionValue>{info.title}</OptionValue>
            </Option>
            <Option>
              <Icon name="Calendar" size="small" />
              <OptionTitle>Tipo de Assinatura</OptionTitle>
              <OptionValue>Mensal</OptionValue>
            </Option>
          </LeftContent>
          <RightContent>
            {isMobile ? (
              <Flag type={getCardFlag(data.card)} />
            ) : (
              <CreditCard
                number={data.card}
                name={data.name}
                cvv={data.cvv}
                expiration={data.expireAt}
                front={true}
                onHandleCardFlag={(flag) => console.log(flag)}
              />
            )}
            <FormContainer onSubmit={handleSumit}>
              {error && <Alert color="error" text={error} />}
              <FormField area="Card" label="Cartão de Crédito">
                <Input
                  inputRef={inputRef.card}
                  id="card"
                  type="card"
                  icon="CreditCard"
                  value={data.card}
                  onChange={(value) => setData({ ...data, card: value })}
                  onComplete={() => inputRef.expiration.current?.focus()}
                  required
                />
              </FormField>
              <FormField area="Expiration" label="Validade">
                <Input
                  inputRef={inputRef.expiration}
                  id="expiratiom"
                  type="expiration"
                  icon="Calendar"
                  placeHolder="MM/AA"
                  value={data.expireAt}
                  onChange={(value) => setData({ ...data, expireAt: value })}
                  onComplete={() => inputRef.cvv.current?.focus()}
                  required
                />
              </FormField>
              <FormField area="CVV" label="CVV">
                <Input
                  inputRef={inputRef.cvv}
                  id="cvv"
                  type="cvv"
                  icon="Locker"
                  value={data.cvv}
                  onChange={(value) => setData({ ...data, cvv: value })}
                  onComplete={() => inputRef.name.current?.focus()}
                  required
                />
              </FormField>
              <FormField area="Name" label="Titular">
                <Input
                  inputRef={inputRef.name}
                  id="name"
                  type="text"
                  icon="User"
                  value={data.name}
                  onChange={(value) => setData({ ...data, name: value })}
                  upper
                  required
                />
              </FormField>
              <ButtonContainer>
                <Button loading={pedding}>Pagar {formatCash(info.service.Plan.value)}</Button>
              </ButtonContainer>
            </FormContainer>
          </RightContent>
        </PageContent>
        <FullContainer active={activePage === 1}>
          <PageContent active={activePage === 1}>
            <FeedbackContainer>
              <Icon name="Check" color="sucess" size="xxlarge" />
              <h2>Pagamento Confirmado</h2>
              <span>
                Seu aplicativo foi enviado para a AppStore e GooglePlay, O prazo de análise das loja é de até 7 dias
                úteis, te aviseramos quando o app estiver disponível para seus clientes.
              </span>
            </FeedbackContainer>
          </PageContent>
        </FullContainer>
        <Copyright>
          <span>
            © {new Date().getFullYear()} - <strong>MeuProvedor</strong>
          </span>
          <span>
            Powered by <strong>R3R</strong>
          </span>
          <br />
          <span>CNPJ: 46.396.114/0001-01</span>
        </Copyright>
      </Container>
    );
  } else if (error || !providerId) {
    return (
      <Container>
        <FullContainer active={true}>
          <PageContent active={true}>
            <FeedbackContainer>
              <Icon name="Warning" color="error" size="xxlarge" />
              <h2>Erro</h2>
              <span>{error}</span>
            </FeedbackContainer>
          </PageContent>
        </FullContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <FullContainer active={true}>
          <Spinner />
        </FullContainer>
      </Container>
    );
  }
};
