import React, { useEffect, useRef, useState } from 'react';

import { CardFlag, Flag } from './Flag';

import styled from 'styled-components';

const CardHeader = styled.div`
  position: relative;
  width: 280px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  justify-self: center;
`;

const FlagContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 29px;
  right: 30px;
`;

export function getCardFlag(number: string) {
  const cardNumber = number.replace(/[^0-9]+/g, '');

  const cards = {
    // Valid: /^[0-9]{16}$/,
    // Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MasterCard: /^5[1-5][0-9]{14}/,
    Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    Amex: /^3[47][0-9]{13}/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    Aura: /^(5078\d{2})(\d{2})(\d{11})$/,
  };

  for (const flag in cards) {
    if (cards[flag as keyof typeof cards].test(cardNumber)) {
      return flag as CardFlag;
    }
  }

  return undefined;
}

type FrontProps = {
  name: string;
  number: string;
  expiration: string;
  onHandleCardFlag: (flag?: CardFlag) => void;
};
export const Front: React.FC<FrontProps> = ({ name, number, expiration, onHandleCardFlag }) => {
  const [cardFlag, setCardFlag] = useState<CardFlag>();

  useEffect(() => {
    // if (number.length > 18) {
    const flag = getCardFlag(number);
    onHandleCardFlag(flag);
    setCardFlag(flag);
    // }
  }, [number]);

  return (
    <CardHeader>
      {cardFlag && (
        <FlagContainer>
          <Flag type={cardFlag} />
        </FlagContainer>
      )}
      <span
        style={{
          zIndex: 1,
          position: 'absolute',
          width: '100%',
          textAlign: 'center',
          top: '85px',
          fontSize: '20px',
          left: 0,
          fontWeight: 500,
        }}
      >
        {number}
      </span>
      <span
        style={{
          zIndex: 1,
          position: 'absolute',
          bottom: '30px',
          fontSize: '14px',
          left: '30px',
          fontWeight: 500,
        }}
      >
        {name}
      </span>
      <span
        style={{
          zIndex: 1,
          position: 'absolute',
          bottom: '30px',
          fontSize: '14px',
          right: '30px',
          fontWeight: 500,
        }}
      >
        {expiration}
      </span>
      <svg width="280" height="175" viewBox="0 0 280 175" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_206_133" maskUnits="userSpaceOnUse" x="0" y="0" width="280" height="175">
          <path
            d="M261.625 0H18.375C8.22677 0 0 8.22677 0 18.375V156.625C0 166.773 8.22677 175 18.375 175H261.625C271.773 175 280 166.773 280 156.625V18.375C280 8.22677 271.773 0 261.625 0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_206_133)">
          <path
            d="M261.625 0H18.375C8.22677 0 0 8.22677 0 18.375V156.625C0 166.773 8.22677 175 18.375 175H261.625C271.773 175 280 166.773 280 156.625V18.375C280 8.22677 271.773 0 261.625 0Z"
            fill="url(#paint0_linear_206_133)"
          />
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M-9.80971 182.328C16.2619 105.516 50.3653 54.2453 92.5005 28.5149C134.636 2.78458 196.934 -7.74618 279.396 -3.07735V182.328H-9.80971Z"
            fill="url(#paint1_linear_206_133)"
          />
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M54.25 182.106C80.3216 105.294 114.425 54.0233 156.56 28.293C198.695 2.56266 260.994 -7.9681 343.456 -3.29927V182.106H54.25Z"
            fill="url(#paint2_linear_206_133)"
          />
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M-4.22552 182.106C-2.18418 131.966 39.395 115.796 120.512 133.594C201.629 151.392 256.781 100.907 285.966 -17.8623L293.323 182.106H-4.22552Z"
            fill="url(#paint3_linear_206_133)"
          />
          <rect x="25.375" y="21.875" width="41.125" height="33.25" rx="4.375" fill="url(#paint4_linear_206_133)" />
          <rect
            opacity="0.7"
            x="27.5625"
            y="24.0626"
            width="36.75"
            height="28.875"
            rx="3.9375"
            stroke="#066be8"
            stroke-width="0.875"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_206_133"
            x1="280"
            y1="175"
            x2="163.453"
            y2="-55.5611"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2A56D7" />
            <stop offset="1" stop-color="#102577" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_206_133"
            x1="287.16"
            y1="50.8378"
            x2="141.076"
            y2="237.695"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#066be8" />
            <stop offset="1" stop-color="#066be8" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_206_133"
            x1="351.22"
            y1="50.6159"
            x2="205.135"
            y2="237.473"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#066be8" />
            <stop offset="1" stop-color="#066be8" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_206_133"
            x1="301.31"
            y1="41.1057"
            x2="143.216"
            y2="235.125"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#066be8" />
            <stop offset="1" stop-color="#066be8" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_206_133"
            x1="25.375"
            y1="21.875"
            x2="65.1875"
            y2="55.125"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F3D3EA" />
            <stop offset="0.494792" stop-color="#C3E0EE" />
            <stop offset="0.979167" stop-color="#FEFAF0" />
          </linearGradient>
        </defs>
      </svg>
    </CardHeader>
  );
};

type BackProps = {
  cvv: string;
};
export const Back: React.FC<BackProps> = ({ cvv }) => {
  return (
    <CardHeader>
      <span
        color="black"
        style={{
          flex: 1,
          zIndex: 1,
          position: 'absolute',
          top: 109,
          right: 40,
        }}
        title={cvv}
      />
      <svg width="100%" height={236} viewBox="0 0 356 236" fill="none">
        <g>
          <mask id="a" x={18} y={10} width={320} height={200}>
            <path
              d="M317 10H39c-11.598 0-21 9.402-21 21v158c0 11.598 9.402 21 21 21h278c11.598 0 21-9.402 21-21V31c0-11.598-9.402-21-21-21z"
              fill="#fff"
            />
          </mask>
          <g mask="url(#a)">
            <path
              d="M317 10H39c-11.598 0-21 9.402-21 21v158c0 11.598 9.402 21 21 21h278c11.598 0 21-9.402 21-21V31c0-11.598-9.402-21-21-21z"
              fill="url(#paint0_linear_5_217)"
            />
            <path
              opacity={0.2}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.789 218.375C36.585 130.59 75.56 71.995 123.715 42.589 171.869 13.182 243.068 1.147 337.31 6.483v211.892H6.789z"
              fill="url(#paint1_linear_5_217)"
            />
            <path
              opacity={0.2}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M80 218.121c29.796-87.785 68.771-146.38 116.926-175.786C245.081 12.929 316.279.893 410.521 6.229v211.892H80z"
              fill="url(#paint2_linear_5_217)"
            />
            <path
              opacity={0.2}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.17 218.121c2.334-57.302 49.853-75.783 142.558-55.442 92.705 20.34 155.736-37.357 189.091-173.093l8.407 228.535H13.171z"
              fill="url(#paint3_linear_5_217)"
            />
          </g>
          <path fill="#202121" d="M18 39H338V89H18z" />
          <rect x={34} y={153} width={47} height={38} rx={5} fill="url(#paint4_linear_5_217)" />
          <rect opacity={0.7} x={36.5} y={155.5} width={42} height={33} rx={4.5} stroke="#066be8" />
          <path fill="#FEFEFE" d="M222 110H321V141H222z" />
          <rect x={34} y={107} width={236} height={38} rx={3} fill="#F7F1F8" />
          <path fill="#FEFAF1" d="M34 112H270V118H34z" />
          <path fill="#FEFAF1" d="M34 123H270V129H34z" />
          <path fill="#FEFAF1" d="M34 134H270V140H34z" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_5_217"
            x1={338}
            y1={210}
            x2={204.803}
            y2={-53.4983}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#523298" />
            <stop offset={1} stopColor="#7B5FAE" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5_217"
            x1={346.183}
            y1={68.1004}
            x2={179.229}
            y2={281.651}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEE" />
            <stop offset={1} stopColor="#102577" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5_217"
            x1={419.394}
            y1={67.8466}
            x2={252.44}
            y2={281.397}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEE" />
            <stop offset={1} stopColor="#102577" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint3_linear_5_217"
            x1={362.355}
            y1={56.9778}
            x2={181.676}
            y2={278.714}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEE" />
            <stop offset={1} stopColor="#102577" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="paint4_linear_5_217" x1={34} y1={153} x2={79.5} y2={191} gradientUnits="userSpaceOnUse">
            <stop stopColor="#F3D3EA" />
            <stop offset={0.494792} stopColor="#C3E0EE" />
            <stop offset={0.979167} stopColor="#FEFAF0" />
          </linearGradient>
        </defs>
      </svg>
    </CardHeader>
  );
};

const Container = styled.div``;

type CreditCardProps = {
  name: string;
  number: string;
  expiration: string;
  cvv: string;
  front: boolean;
  onHandleCardFlag: (flag?: CardFlag) => void;
};
export const CreditCard: React.FC<CreditCardProps> = ({
  name,
  number,
  expiration,
  cvv,
  front = true,
  onHandleCardFlag,
}) => {
  const cardRef = useRef(null);

  useEffect(() => {
    console.log(number);
  }, [number]);

  return (
    <Container
      ref={cardRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      {front ? (
        <Front
          number={number}
          name={name}
          expiration={expiration}
          onHandleCardFlag={(flag) => {
            onHandleCardFlag(flag);
          }}
        />
      ) : (
        <Back cvv={cvv} />
      )}
    </Container>
  );
};
