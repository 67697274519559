import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Download: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 22 24" fill="none">
      <path d="M11 21l-8-9h6V0h4v12h6l-8 9zm9-1v2H2v-2H0v4h22v-4h-2z" fill={colors[color]} />
    </svg>
  );
};
