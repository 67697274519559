import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const ArrowRight: React.FC<VectorProps> = ({ color = 'light', size = 'small' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 20 22" fill="none">
      <path
        d="M6.873 8.813L.28 2.222A.955.955 0 010 1.54c0-.258.1-.5.281-.681L.86.282A.956.956 0 011.54 0c.259 0 .5.1.682.282L10.07 8.13a.956.956 0 01.281.683c0 .26-.099.502-.281.684l-7.84 7.84a.956.956 0 01-.682.282c-.258 0-.5-.1-.682-.281L.29 16.76a.965.965 0 010-1.363l6.584-6.584z"
        fill={colors[color]}
      />
    </svg>
  );
};
