import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Key: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 99 101" fill="none">
      <path
        d="M90.2 8.857C84.518 3.146 76.96 0 68.924 0S53.33 3.146 47.646 8.857c-8.233 8.275-10.931 20.275-7.193 31.179L1.749 78.934a3.687 3.687 0 00-1.056 2.243l-.676 6.928a3.691 3.691 0 001.056 2.962l8.481 8.524a3.647 3.647 0 002.254 1.062l3.582.331a3.655 3.655 0 002.926-1.062l6.53-6.564c.363-.363.645-.8.828-1.28l4.239-11.057h5.692c.995 0 1.947-.407 2.637-1.126l6.643-6.925c.309-.322.557-.697.732-1.108l2.74-6.448 2.367.09a3.648 3.648 0 002.695-1.043l5.73-5.618a29.833 29.833 0 009.785 1.635c8.035 0 15.587-3.144 21.267-8.852 11.732-11.792 11.732-30.977 0-42.769zM72.998 18.35a5.437 5.437 0 013.88-1.615c1.465 0 2.843.573 3.879 1.615a5.54 5.54 0 010 7.799 5.436 5.436 0 01-3.88 1.615 5.435 5.435 0 01-3.879-1.615 5.54 5.54 0 010-7.799z"
        fill={colors[color]}
      />
    </svg>
  );
};
