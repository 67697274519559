import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Checkout } from './pages/Checkout';

export const routes = {
  index: '/',
  dashboard: '/dashboard',
  listApps: '/listApps',
};

export const PublicRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.index} element={<Checkout />} />
      </Routes>
    </BrowserRouter>
  );
};

export const App = () => {
  return <PublicRoutes />;
};
