import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Open: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 19 18" fill="none">
      <path
        d="M9.235 2.825a.75.75 0 100-1.5v1.5zm8.147 6.594a.75.75 0 00-1.5 0h1.5zm-8.99-.217a.75.75 0 101.057 1.063L8.39 9.202zm9.289-8.177h.75a.75.75 0 00-.75-.75v.75zm-5.036-.75a.75.75 0 000 1.5v-1.5zm4.286 5.63a.75.75 0 001.5 0h-1.5zm-7.696-4.58H3.523v1.5h5.712v-1.5zM.773 4.075v10.95h1.5V4.075h-1.5zm2.75 13.7h11.109v-1.5H3.523v1.5zm13.859-2.75V9.42h-1.5v5.606h1.5zm-7.933-4.76l8.76-8.708L17.152.493l-8.76 8.709 1.057 1.063zM17.68.275h-5.036v1.5h5.036v-1.5zm-.75.75v4.88h1.5v-4.88h-1.5zm-2.3 16.75a2.75 2.75 0 002.75-2.75h-1.5c0 .69-.559 1.25-1.25 1.25v1.5zM.774 15.025a2.75 2.75 0 002.75 2.75v-1.5c-.69 0-1.25-.56-1.25-1.25h-1.5zm2.75-13.7a2.75 2.75 0 00-2.75 2.75h1.5c0-.69.56-1.25 1.25-1.25v-1.5z"
        fill={colors[color]}
      />
    </svg>
  );
};
