import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Company: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 100 84" fill="none">
      <path
        d="M94.898 78.827V63.114h-16.53V15.865h-6.572V7.031H53.06v-7H46.94v7H28.204v8.834h-6.572v47.25H5.102v15.712H0v5.143h100v-5.143h-5.102zm-23.49 0h-6.122V29.794h-5.86v49.034h-6.122V29.794h-6.19v49.034h-6.122V29.794h-6.278v49.034h-6.122V24.65h42.816v54.177z"
        fill={colors[color]}
      />
    </svg>
  );
};
