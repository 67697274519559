import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icons';

const InputContainer = styled.div<{ disabled: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-content: center;
  width: max-content;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const StyledLabel = styled.div`
  cursor: pointer;
  font-weight: 500;
  word-wrap: break-word;
`;

type Props = {
  label?: string;
  onChange?: (e: boolean) => void;
  value?: boolean;
  disabled?: boolean;
};
export const CheckBox: React.FC<Props> = ({ label, onChange, value = false, disabled = false }) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <InputContainer disabled={disabled}>
      <Icon
        name={checked ? 'CircleChecked' : 'Circle'}
        color={checked ? 'primary' : 'black'}
        size="small"
        onClick={() => {
          onChange?.call(this, !checked);
          setChecked(!checked);
        }}
      />
      {label && (
        <StyledLabel
          onClick={() => {
            onChange?.call(this, !checked);
            setChecked(!checked);
          }}
        >
          {label}
        </StyledLabel>
      )}
    </InputContainer>
  );
};
