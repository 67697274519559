import React, { useState, useEffect, useContext, createContext } from 'react';
import { Confirmation, ConfirmationProps } from '../components/Confirmation';
import { Feedback, FeedbackProps } from '../components/Feedback';

type Context = {
  message(data: FeedbackProps): void;
  confirmation(data: ConfirmationProps): void;
};

export const FeedbackContext = createContext<Context>({} as never);

export const FeedbackProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [feedbackData, setFeedbackData] = useState<FeedbackProps>();
  const [confirmationData, setConfirmationData] = useState<ConfirmationProps>();

  useEffect(() => {
    if (feedbackData?.visible === true) {
      setTimeout(() => {
        setFeedbackData({ ...feedbackData, visible: false });
      }, 5000);
    }
  }, [feedbackData]);

  const message = (data: FeedbackProps) => {
    setFeedbackData({ ...data, visible: true });
  };

  const confirmation = (data: ConfirmationProps) => {
    setConfirmationData({ ...data, visible: true });
  };

  return (
    <FeedbackContext.Provider value={{ message, confirmation }}>
      {feedbackData && (
        <Feedback
          message={feedbackData.message}
          error={feedbackData.error}
          onClose={() => feedbackData.onClose?.call(this)}
          visible={feedbackData.visible}
        />
      )}
      {confirmationData && (
        <Confirmation
          title={confirmationData.title}
          onHandle={(result) => {
            if (confirmationData) {
              setConfirmationData({ ...confirmationData, visible: false });
              confirmationData.onHandle(result);
            }

            if (!result) setConfirmationData(undefined);
          }}
          onHandleOption={confirmationData.onHandleOption}
          clipboard={confirmationData.clipboard}
          visible={confirmationData.visible}
        />
      )}
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = () => {
  const context = useContext(FeedbackContext);
  return context;
};
