import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Warning: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 555 506"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M538.5 362.199L356.5 46.7999C340.1 18.3999 309.8 0.899902 276.999 0.899902C244.199 0.899902 213.899 18.3999 197.499 46.7999L12.3 367.6C-4.1 396 -4.1 431 12.3 459.4C28.7 487.8 59 505.3 91.8 505.3H462.2C462.301 505.3 462.4 505.3 462.4 505.3C513.1 505.3 554.2 464.199 554.2 413.5C554.2 394.5 548.4 376.8 538.5 362.199ZM316.3 392.899C316.3 414.599 299.6 431.199 277.1 431.199C254.6 431.199 237.9 414.599 237.9 392.899V392C237.9 370.399 254.6 353.699 277.1 353.699C299.6 353.699 316.3 370.3 316.3 392V392.899ZM317.2 134.7L297.8 304.1C296.5 316.3 288.4 323.9 277.1 323.9C265.8 323.9 257.7 316.2 256.4 304.1L237 134.6C235.7 121.5 242.801 111.6 255 111.6H299.1C311.3 111.7 318.5 121.6 317.2 134.7Z"
        fill={colors[color]}
      />
    </svg>
  );
};
