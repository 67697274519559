import React from 'react';
import { colors, sizes, VectorProps } from './Icon';

export const Circle: React.FC<VectorProps> = ({ size = 'small', color = 'light' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="12.25" stroke={colors[color]} strokeWidth="1.5" />
    </svg>
  );
};

