import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const WhatsApp: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 100 100" fill="none">
      <path
        d="M73.995 57.461c-.195-.093-7.486-3.683-8.781-4.15-.529-.19-1.096-.375-1.698-.375-.984 0-1.811.491-2.456 1.455-.728 1.082-2.932 3.659-3.614 4.429-.088.101-.21.223-.283.223-.065 0-1.193-.465-1.535-.613-7.82-3.397-13.756-11.566-14.57-12.944-.116-.198-.121-.288-.122-.288.028-.105.291-.368.427-.505.397-.392.827-.91 1.243-1.411.198-.237.395-.475.589-.7.604-.702.873-1.247 1.184-1.88l.164-.327c.76-1.512.11-2.788-.1-3.2-.172-.345-3.25-7.774-3.577-8.555-.787-1.883-1.827-2.76-3.273-2.76-.134 0 0 0-.562.023-.684.03-4.413.52-6.062 1.56-1.748 1.102-4.706 4.615-4.706 10.795 0 5.561 3.529 10.812 5.044 12.81.038.05.107.152.207.298 5.803 8.475 13.037 14.756 20.37 17.685 7.06 2.82 10.404 3.146 12.305 3.146.799 0 1.438-.062 2.002-.118l.358-.034c2.439-.216 7.798-2.994 9.018-6.382.96-2.668 1.213-5.584.574-6.642-.437-.72-1.192-1.081-2.147-1.54z"
        fill={colors[color]}
      />
      <path
        d="M50.888 0C23.805 0 1.77 21.868 1.77 48.748A48.39 48.39 0 008.505 73.4L.069 98.284a1.298 1.298 0 001.623 1.655l25.948-8.245c7.1 3.793 15.126 5.796 23.248 5.796C77.968 97.49 100 75.624 100 48.748 100 21.868 77.968 0 50.888 0zm0 87.336a38.91 38.91 0 01-21.408-6.382 1.297 1.297 0 00-1.106-.152l-12.998 4.132 4.196-12.38a1.3 1.3 0 00-.182-1.184c-4.846-6.62-7.407-14.443-7.407-22.622 0-21.28 17.452-38.593 38.904-38.593 21.45 0 38.9 17.313 38.9 38.593 0 21.277-17.45 38.588-38.9 38.588z"
        fill={colors[color]}
      />
    </svg>
  );
};
