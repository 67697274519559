import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Exit: React.FC<VectorProps> = ({ color = 'light', size = 'small' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 22 23" fill="none">
      <path
        d="M20.958 22.36H10.49a1.042 1.042 0 010-2.084h9.425V2.083H10.49a1.042 1.042 0 010-2.083h10.468C21.533 0 22 .453 22 1.03v20.286c0 .575-.467 1.044-1.042 1.044z"
        fill={colors[color]}
      />
      <path
        d="M15.38 10.138H3.549L7.637 6.05a1.045 1.045 0 000-1.475 1.045 1.045 0 00-1.475 0l-5.857 5.86a1.045 1.045 0 000 1.474l5.857 5.862a1.039 1.039 0 001.473 0 1.045 1.045 0 000-1.475l-4.068-4.074h11.815a1.042 1.042 0 10-.002-2.084z"
        fill={colors[color]}
      />
    </svg>
  );
};
