import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { CheckBox } from '../CheckBox';
import { Input } from '../Input';

const StyledConfirmation = styled.div<{ show: boolean }>`
  position: fixed;
  display: flex;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  top: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.7;
`;

const Box = styled.div`
  display: grid;
  grid-template:
    'Title Title' max-content
    'No Yes' max-content /
    1fr 1fr;

  gap: 28px 8px;
  width: 360px;
  padding: 25px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 5px;

  justify-content: center;
  align-items: center;
  justify-items: center;
`;

const Title = styled.span`
  grid-area: Title;
  font-weight: 600;
  width: 250px;
  text-align: center;
  color: #0e0e0e;
`;

const OptionsContent = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  grid-area: 2 / span 2;
`;

export type Props = {
  title: string;
  clipboard?: {
    options?: {
      selected?: number;
      values: {
        title: string;
        value: string;
      }[];
    };
  };
  visible?: boolean;
  onHandle: (result: boolean) => void;
  onHandleOption?: (result: string) => void;
};

export const Confirmation: React.FC<Props> = ({ title, visible = false, clipboard, onHandle, onHandleOption }) => {
  const [clipboardText, setClipboardText] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  function copyToClipboard() {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard.writeText(clipboardText ?? '');
    }
  }

  if (clipboard) {
    const [selected, setSelected] = useState(clipboard.options?.selected);

    useEffect(() => {
      setSelected(clipboard.options?.selected);
    }, [clipboard.options?.selected]);

    return (
      <StyledConfirmation show={visible}>
        <Overlay onClick={() => onHandle(false)} />
        <Box>
          <Title data-cy="confirmation-title">{title}</Title>
          <OptionsContent>
            {clipboard.options?.values.map((value, index) => {
              return (
                <CheckBox
                  key={index}
                  label={value.title}
                  value={selected === index}
                  onChange={() => {
                    setSelected(index);
                    setClipboardText(value.value);
                    onHandleOption?.call(this, value.value);
                  }}
                />
              );
            })}
          </OptionsContent>
          <Input
            ref={inputRef}
            id="input"
            height="small"
            value={clipboardText}
            type="text"
            style={{ gridArea: '3 / span 2', width: '-webkit-fill-available' }}
            disabled
          />
          <Button size="small" onClick={() => copyToClipboard()} disabled={!clipboardText} fullWidth>
            Copiar
          </Button>
          <Button size="small" onClick={() => window.open(clipboardText)} disabled={!clipboardText} fullWidth>
            Baixar
          </Button>
        </Box>
      </StyledConfirmation>
    );
  }
  return (
    <StyledConfirmation show={visible}>
      <Overlay onClick={() => onHandle(false)} />
      <Box>
        <Title data-cy="confirmation-title">{title}</Title>
        <Button size="small" onClick={() => onHandle?.call(this, true)} fullWidth>
          Sim
        </Button>
        <Button size="small" onClick={() => onHandle(false)} fullWidth>
          Não
        </Button>
      </Box>
    </StyledConfirmation>
  );
};
