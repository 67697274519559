import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Check: React.FC<VectorProps> = ({ color = 'light', size = 'small' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 15 15" fill="none">
      <path
        d="M6.62.186a7.327 7.327 0 00-4.314 2.119C.892 3.715.15 5.503.15 7.497c0 1.994.742 3.778 2.156 5.193A7.222 7.222 0 007.5 14.846c2.584 0 4.944-1.332 6.3-3.554 1.107-1.818 1.356-4.112.669-6.148C13.564 2.481 11.238.576 8.43.197 8.034.145 7.005.138 6.62.187zm1.358 1.446a5.898 5.898 0 015.055 7.873 7.497 7.497 0 01-.676 1.311c-.808 1.17-2.032 2.032-3.378 2.374-.89.228-1.801.252-2.68.07-3.161-.657-5.22-3.8-4.562-6.96a5.914 5.914 0 014.575-4.568 5.428 5.428 0 011.666-.1z"
        fill={colors[color]}
      />
      <path
        d="M8.258 6.721L6.447 8.532l-.777-.776-.776-.776-.518.517-.517.518 1.304 1.304 1.3 1.3L8.8 8.285l2.34-2.34-.518-.517a11.28 11.28 0 00-.535-.517c-.01 0-.831.814-1.828 1.811z"
        fill={colors[color]}
      />
    </svg>
  );
};
