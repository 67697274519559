export function getRamdomString(size: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < size; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function formatCash(value: number) {
  return `R$ ${value / 100}`;
}

export function formatAPIDate(str: string) {
  const month = str.split('/')[0];
  const year = str.split('/')[1];
  return `20${year}-${month}`;
}
