import React from 'react';

import { App } from './App';
import { createRoot } from 'react-dom/client';
import { FeedbackProvider } from './context/feedback';

const root = createRoot(document.getElementById('app') as HTMLElement);

root.render(
  <FeedbackProvider>
    <App />
  </FeedbackProvider>
);
