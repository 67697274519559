import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Chart: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} fill="none" viewBox="0 0 51 54">
      <path
        d="M42.5 21.4 22 32.7c-1.4.7-3-.3-3-1.8V6.4c0-1-1-1.8-1.9-1.5-10 2.8-17.2 12.5-16 23.6 1.1 10.1 9.2 18.3 19.4 19.4C33.8 49.3 45 39 45 26c0-1.2-.1-2.4-.3-3.6-.2-1-1.3-1.5-2.2-1Z"
        fill={colors[color]}
      />
      <path
        d="m24.7 26 19.7-10.5c1.2-.6 1.6-2.2.8-3.3C40.7 6 33.7 1.5 25.7.2 24.3-.1 23 1 23 2.4V25c0 .9.9 1.4 1.7 1Z"
        fill={colors[color]}
      />
    </svg>
  );
};
