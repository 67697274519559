import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Facebook: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 33 34" fill="none">
      <path
        d="M32.484 17C32.484 7.902 25.33.531 16.5.531S.516 7.902.516 17c0 8.22 5.845 15.033 13.486 16.27V21.76h-4.06V17h4.06v-3.628c0-4.128 2.385-6.407 6.038-6.407 1.749 0 3.578.321 3.578.321v4.05h-2.016c-1.985 0-2.604 1.27-2.604 2.573V17h4.433l-.71 4.76h-3.723v11.51c7.641-1.237 13.486-8.05 13.486-16.27z"
        fill={colors[color]}
      />
    </svg>
  );
};
