import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icons';

type StyledFeedbackProps = {
  show: boolean;
  error: boolean;
};
const StyledFeedback = styled.div<StyledFeedbackProps>`
  position: fixed;
  z-index: 1;
  display: grid;
  padding: 10px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  top: ${(props) => (props.show ? '65px' : '-25px')};
  right: 20px;
  border-top: ${(props) => (props.error ? '3px solid red' : '3px solid green')};
  color: white;
  grid-template-columns: max-content max-content;
  gap: 8px;

  justify-content: center;
  align-items: center;
  transition: all 0.5s;

  background-color: #2b3754;
`;

export type Props = {
  message: string;
  visible?: boolean;
  error?: boolean;
  onClose?: () => void;
};
export const Feedback: React.FC<Props> = ({ message, visible = false, error = false, onClose }) => {
  useEffect(() => {
    if (!visible) onClose?.call(this);
  }, [visible]);

  return (
    <StyledFeedback show={visible} error={error}>
      <Icon name={error ? 'Info' : 'Check'} />
      <span>{message}</span>
    </StyledFeedback>
  );
};
