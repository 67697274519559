import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const User: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 31 33" fill="none">
      <path
        d="M30.312 27.45c0 1.708-.548 3.081-1.593 4.067-1.044.987-2.417 1.483-4.125 1.483H5.776c-1.76 0-3.133-.496-4.184-1.483C.548 30.525 0 29.151 0 27.45c0-.768 0-1.483.058-2.199.058-.715.168-1.483.277-2.366.11-.883.329-1.592.548-2.308a9.91 9.91 0 01.935-2.088c.387-.658.825-1.264 1.321-1.76a5.074 5.074 0 011.818-1.154 6.866 6.866 0 012.418-.438c.11 0 .438.167.883.438.445.27.993.658 1.592 1.044.606.387 1.373.716 2.308 1.045.935.328 1.928.438 2.863.438.934 0 1.927-.168 2.862-.438.934-.33 1.708-.658 2.308-1.045.606-.386 1.154-.715 1.592-1.044.438-.329.767-.438.883-.438.883 0 1.65.167 2.418.438.767.27 1.321.658 1.817 1.154a7.393 7.393 0 011.322 1.76c.387.715.716 1.373.935 2.088.219.716.438 1.483.548 2.308.11.825.277 1.593.277 2.366 0 .774.329 1.431.329 2.198zM23.433 8.252c0 2.256-.825 4.235-2.417 5.834-1.592 1.592-3.578 2.417-5.834 2.417-2.257 0-4.236-.825-5.834-2.417-1.6-1.593-2.418-3.578-2.418-5.834 0-2.257.825-4.236 2.418-5.835C10.94.82 12.925 0 15.182 0c2.256 0 4.235.825 5.834 2.417 1.592 1.6 2.417 3.578 2.417 5.835z"
        fill={colors[color]}
      />
    </svg>
  );
};
