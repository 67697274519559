import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Wireless: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 107 77" fill="none">
      <path
        d="M53.524.688C34.64.688 16.727 7.753 2.973 20.563l-2.41 2.245 2.34 2.316 7.633 7.562 2.197 2.175 2.27-2.08c10.516-9.666 24.175-15.007 38.473-15.007a56.675 56.675 0 0138.475 15.007l2.268 2.08 2.198-2.175 7.634-7.562 2.339-2.316-2.41-2.245C90.273 7.753 72.335.688 53.524.688z"
        fill={colors[color]}
      />
      <path
        d="M86.917 38.594A49.615 49.615 0 0053.57 25.738H51.467l-.023.047a49.662 49.662 0 00-31.337 12.81l-2.482 2.245 2.387 2.363 7.728 7.657 2.15 2.127 2.27-1.985c5.979-5.247 13.565-8.154 21.34-8.154 7.822 0 15.409 2.907 21.388 8.154l2.268 1.985 2.151-2.127 7.728-7.657 2.387-2.363-2.505-2.246z"
        fill={colors[color]}
      />
      <path
        d="M53.547 76.313l2.269-2.246 12.478-12.336L70.8 59.25l-2.741-2.245c-3.64-2.695-7.657-4.727-14.534-4.727-6.854 0-10.611 2.221-14.535 4.727l-2.717 2.245 2.505 2.481 12.478 12.336 2.292 2.246z"
        fill={colors[color]}
      />
    </svg>
  );
};
