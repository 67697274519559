import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Cross: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 22 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 4.925 4.925 0 11 0s11 4.925 11 11-4.925 11-11 11S0 17.075 0 11zm11.027 1.063L8.71 14.815 7.397 13.31l2.34-2.779-2.34-2.779L8.71 6.248l2.317 2.751 2.316-2.751 1.314 1.504-2.34 2.78 2.34 2.778-1.313 1.505-2.317-2.752z"
        fill={colors[color]}
      />
    </svg>
  );
};
