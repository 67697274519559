import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Instagram: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 32 32" fill="none">
      <path
        d="M16.007 7.914c-4.543 0-8.207 3.607-8.207 8.079s3.664 8.079 8.207 8.079c4.543 0 8.207-3.607 8.207-8.079s-3.664-8.079-8.207-8.079zm0 13.331c-2.936 0-5.336-2.355-5.336-5.252s2.393-5.252 5.336-5.252c2.943 0 5.336 2.355 5.336 5.252s-2.4 5.252-5.336 5.252zM26.464 7.584c0 1.047-.857 1.884-1.914 1.884a1.895 1.895 0 01-1.914-1.884c0-1.041.857-1.885 1.914-1.885s1.914.844 1.914 1.885zM31.9 9.496c-.121-2.524-.707-4.76-2.586-6.602C27.443 1.052 25.171.475 22.607.348 19.964.201 12.043.201 9.4.348c-2.557.12-4.829.697-6.707 2.539C.814 4.729.236 6.965.107 9.489c-.15 2.602-.15 10.4 0 13 .122 2.525.707 4.761 2.586 6.603 1.878 1.842 4.143 2.419 6.707 2.546 2.643.147 10.564.147 13.207 0 2.564-.12 4.836-.697 6.707-2.546 1.872-1.842 2.457-4.078 2.586-6.602.15-2.602.15-10.392 0-12.994zm-3.414 15.785c-.557 1.378-1.636 2.44-3.043 2.996-2.107.822-7.107.632-9.436.632-2.328 0-7.336.183-9.436-.632A5.36 5.36 0 013.53 25.28c-.836-2.074-.643-6.996-.643-9.288s-.186-7.221.643-9.288c.557-1.378 1.635-2.44 3.042-2.996 2.108-.822 7.108-.632 9.436-.632 2.329 0 7.336-.183 9.436.632a5.36 5.36 0 013.043 2.996c.835 2.074.642 6.996.642 9.288s.193 7.221-.642 9.288z"
        fill={colors[color]}
      />
    </svg>
  );
};
