import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Info: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 32 32" fill="none">
      <path
        d="M17.188 2.75C9.215 2.75 2.75 9.215 2.75 17.188c0 7.972 6.465 14.437 14.438 14.437 7.972 0 14.437-6.465 14.437-14.438 0-7.972-6.465-14.437-14.438-14.437zm1.03 21.398a.259.259 0 01-.257.258h-1.547a.259.259 0 01-.258-.258v-8.765c0-.142.116-.258.258-.258h1.547c.142 0 .258.116.258.258v8.765zm-1.03-11.085a1.548 1.548 0 010-3.094 1.547 1.547 0 010 3.094z"
        fill={colors[color]}
      />
    </svg>
  );
};
