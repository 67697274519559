import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const CreditCard: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 22 22" fill="none">
      <path
        d="M20.167 3.667H1.833A1.834 1.834 0 000 5.5v11c0 1.012.821 1.833 1.833 1.833h18.334A1.834 1.834 0 0022 16.5v-11a1.834 1.834 0 00-1.833-1.833zm0 12.375a.459.459 0 01-.459.458H2.292a.458.458 0 01-.459-.458v-5.959h18.334v5.959zm0-8.709H1.833V5.958c0-.253.206-.458.459-.458h17.416c.253 0 .459.205.459.458v1.375zm-8.25 5.5h-8.25v-.916h8.25v.916zm-2.75 1.834h-5.5v-.917h5.5v.917zm9.166-1.834h-2.75v-.916h2.75v.916z"
        fill={colors[color]}
      />
    </svg>
  );
};
