import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Bars: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 31 31" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.875 3.875a2 2 0 00-2 2v19.25a2 2 0 002 2h19.25a2 2 0 002-2V5.875a2 2 0 00-2-2H5.875zM10.042 15.5a1 1 0 00-1 1v4.458a1 1 0 001 1h.583a1 1 0 001-1V16.5a1 1 0 00-1-1h-.583zm4.166-5.458a1 1 0 011-1h.584a1 1 0 011 1v10.916a1 1 0 01-1 1h-.584a1 1 0 01-1-1V10.042zm6.167 2.875a1 1 0 00-1 1v7.041a1 1 0 001 1h.583a1 1 0 001-1v-7.041a1 1 0 00-1-1h-.583z"
        fill={colors[color]}
      />
    </svg>
  );
};
