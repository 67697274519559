import axios from 'axios';
import { formatAPIDate } from './global';

const API_BASE_URL = String(process.env['API']);

export type ServiceInfoResponse = {
  title: string;
  cnpj: string;
  service: {
    signed: boolean;
    startedAt: string | null;
    includes: { title: string; operation: 'ADD' | 'SUB'; value: number }[];
    Plan: {
      title: string;
      description: string;
      value: number;
    };
  };
};
export const serviceInfo = async (providerId: string) => {
  return await axios({
    method: 'get',
    url: `${API_BASE_URL}/service`,
    params: {
      id: providerId,
    },
  })
    .then((res) => {
      return res.data as ServiceInfoResponse;
    })
    .catch((err) => {
      throw err.response;
    });
};

export type SignServiceInput = {
  name: string;
  card: string;
  expireAt: string;
  cvv: string;
};
export const signService = async (providerId: string, data: SignServiceInput) => {
  return await axios({
    method: 'post',
    url: `${API_BASE_URL}/service/sign`,
    data: {
      providerId,
      ...data,
      expireAt: formatAPIDate(data.expireAt),
    },
  })
    .then((res) => {
      return res.data as ServiceInfoResponse;
    })
    .catch((err) => {
      throw err.response;
    });
};
