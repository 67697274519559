import React from 'react';

import { VectorProps, colors, sizes } from './Icon';

export const Movie: React.FC<VectorProps> = ({ color = 'light', size = 'large' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 103 103" fill="none">
      <path
        d="M0 0v103h103V0H0zm18.778 94.148H8.546V83.918h10.231v10.231zm0-18.766H8.546V65.151h10.231v10.231zm0-18.766H8.546V46.384h10.231v10.232zm0-18.767H8.546V27.618h10.231v10.231zm0-18.766H8.546V8.851h10.231v10.232zm57.238 79.625H26.984V53.646h49.032v45.062zm0-49.354H26.984V4.292h49.032v45.062zm18.438 44.794H84.222V83.918h10.232v10.231zm0-18.766H84.222V65.151h10.232v10.231zm0-18.766H84.222V46.384h10.232v10.232zm0-18.767H84.222V27.618h10.232v10.231zm0-18.766H84.222V8.851h10.232v10.232z"
        fill={colors[color]}
      />
    </svg>
  );
};
